export interface HTTPError {
  status: number
  data: {
    code: string
    error: string
  }
}

export const createErrors = <T extends { [key: string]: (...args: any[]) => HTTPError }>(errors: T): T => {
  return errors
}

export class ExpectedHTTPError extends Error {
  constructor(public httpResponse: HTTPError) {
    super(`HTTP error with ${httpResponse.status} (${httpResponse.data.code}): ${httpResponse.data.error}`)
  }
}
